import { styled } from '@compiled/react';
import React, { useRef, useEffect, useState, type FC } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { useFeatureDiscovery } from '@confluence/feature-discovery';
import { LoadableAfterPaint } from '@confluence/loadable';

import { useHighlightAcronyms, type HighlightPhraseNodeType } from './useHighlightAcronyms';
import { READING_AIDS_ACRONYM_SPOTLIGHT_MESSAGE_ID } from './ReadingAidsAcronymSpotlight';
import { CurrentSelectionSpan, ReadingAidsPopup } from './ReadingAidsPopup';
import { useReadingAidsActions, useReadingAidsState } from './ReadingAidsStore';

const readingAidsPopupPortalContainerId = 'reading-aids-portal-container';

type ReadingAidsAcronymsHighlighterProps = {
	contentId: string;
};

const ReadingAidsAcronymSpotlight = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ReadingAidsAcronymSpotlight" */
				'./ReadingAidsAcronymSpotlight'
			)
		).ReadingAidsAcronymSpotlight,
});

const getHighlightCategories = (nodes: HighlightPhraseNodeType[]) => {
	const categories = new Map<string, number>();
	try {
		nodes.forEach((node) => {
			const curCategoryCount = categories.get(node.category);
			if (!!curCategoryCount) {
				categories.set(node.category, curCategoryCount + 1);
			} else {
				categories.set(node.category, 1);
			}
		});
	} catch {
		return undefined;
	}
	return Object.fromEntries(categories);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	position: 'relative',
	overflow: 'visible',
	zIndex: 14,
});

const ReadingAidsAcronymsHighlighterComponent: FC<ReadingAidsAcronymsHighlighterProps> = ({
	contentId,
}) => {
	const {
		showPopup,
		autohighlightContext,
		autohighlightSelectionRect,
		autohighlightText,
		sessionId,
		showSpotlight,
	} = useReadingAidsState();
	const portalContainerRef = useRef<HTMLDivElement | null>(null);
	const { hideAutohighlightPopup, setPortalContainerRef } = useReadingAidsActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { acronyms, loading } = useHighlightAcronyms({});

	const [spotlightEnabled, stopSpotlight] = useFeatureDiscovery(
		READING_AIDS_ACRONYM_SPOTLIGHT_MESSAGE_ID,
	);
	const [spotlightActive, setSpotlightActive] = useState<boolean>(false);

	const selectionRect = {
		top: autohighlightSelectionRect.top,
		left: autohighlightSelectionRect.left,
		width: autohighlightSelectionRect.width,
		height: autohighlightSelectionRect.height,
	};

	const endSpotlight = () => {
		void stopSpotlight();
		setSpotlightActive(false);
	};

	useEffect(() => {
		if (!loading) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'shown',
					actionSubject: 'readingAidsAcronymHighlights',
					source: 'viewPageScreen',
					attributes: {
						contentId,
						numHighlights: acronyms.length,
						categories: getHighlightCategories(acronyms),
						beAcronymsEnabled: true,
					},
				},
			}).fire();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId, createAnalyticsEvent, loading]);

	useEffect(() => {
		if (spotlightEnabled && showSpotlight) {
			setSpotlightActive(true);

			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					source: 'viewPageScreen',
					name: 'readingAidsAcronymSpotlight',
					attributes: {
						contentId,
					},
				},
			}).fire();
		}
	}, [contentId, createAnalyticsEvent, showSpotlight, spotlightEnabled]);

	useEffect(() => {
		void setPortalContainerRef({ portalContainerRef });
	}, [portalContainerRef, setPortalContainerRef]);

	return (
		<Container id={readingAidsPopupPortalContainerId} ref={portalContainerRef}>
			<SpotlightTarget name={READING_AIDS_ACRONYM_SPOTLIGHT_MESSAGE_ID}>
				<CurrentSelectionSpan
					id={READING_AIDS_ACRONYM_SPOTLIGHT_MESSAGE_ID}
					top={selectionRect.top}
					left={selectionRect.left}
					width={selectionRect.width}
					height={selectionRect.height}
				/>
			</SpotlightTarget>
			<SpotlightTransition>
				{spotlightActive && (
					<ReadingAidsAcronymSpotlight contentId={contentId} onDismissClick={endSpotlight} />
				)}
			</SpotlightTransition>
			{showPopup && (
				<ReadingAidsPopup
					selectedText={autohighlightText}
					additionalContext={autohighlightContext}
					selectionRect={selectionRect}
					isReadingAidsOpen={showPopup}
					onResult={() => {}}
					onClose={hideAutohighlightPopup}
					contentId={contentId}
					popupPortalContainerId={readingAidsPopupPortalContainerId}
					isAutohighlighted
					sessionId={sessionId}
				/>
			)}
		</Container>
	);
};

export const ReadingAidsAcronymsHighlighter = withErrorBoundary({
	attribution: Attribution.KNOWLEDGE_DISCOVERY,
})(ReadingAidsAcronymsHighlighterComponent);
